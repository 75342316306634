html, body {
  height: 100%;
}

h3 {
  margin: 0 !important;
}

a[target]:not(.btn) {
  text-decoration: none !important;
}

body {
  margin: 0;
  background-color: #fafbfb !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// For case editor
.mat-tab-body {
  .mat-tab-body-content {
    padding-top: 15px;
    padding-bottom: 20px;
    //padding-right: 0.75rem;
  }
}

th {
  border-top: none !important;
}

.nav-link {
  color: #ffffff;
}



.navigation-bar.client-nav {
  background-color: #FAFBFE;
}

.navbar-dark.client-nav .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(34, 45, 101, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

li.nav-item.active .nav-link {
  color: #ffffff;
}

li.nav-item.active {
  //border-bottom: 3px solid white;
}

li.nav-item {
  position: relative !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-dark.client-nav .navbar-nav .nav-link {
  color: #000000;
}

li.nav-item:after {
  content: "";
  display: block;
  width: 0;
  background: #ffffff;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: -5px;
  //transition: width 0.3s ease-in;
}

li.nav-item.active:after {
  content: "";
  display: block;
  width: 100%;
  background: #ffffff;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: -5px;
  transition: width 0.1s ease-in;
}

.client-nav li.nav-item.active:after {
  background: #000000;
}

.mat-row:hover {
  //background-color: mat.get-color-from-palette($primary-palette);
  background-color: #E0EFCE;
  cursor: pointer;
}

.mat-row.active {
  background-color: #E0EFCE;
}

.client-upload a, .client-upload .uploaded-file-name {
  color: #222d65;
}

.client-upload .spinner-button button {
  background-color: #222d65;
}

.client-upload .mat-progress-spinner circle {
  stroke: #222d65;
}

.mat-row:hover > td, .mat-row:hover > td a {
  color: #000;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.bulk-actions {
  .spinner-button {
    margin-top: 0 !important;
  }
}

.modal-backdrop {
  z-index: -1;
}

.modal {
  background-color: rgba(0, 0, 0, 0.22);
}

.inline-form-button {
  .spinner-button {
    margin: 0 !important;
  }
}

.save-payment-note {
  .spinner-button {
    margin: 0 !important;
  }
}

.cdk-overlay-container {
  z-index: 3200 !important;
}

.cdk-overlay-pane {
  z-index: 3200
}

.outcome-wrap .card-header {
  background-color: #b9b9b9;
}

.outcome-wrap .card.selectable {
  cursor: pointer;
}

.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Añadir";
}

.page-content {
  padding: 1.5rem 1rem;
  padding-bottom: 0 !important;
}

.custom-mat-dialog .mat-dialog-container {
  overflow: hidden;
}

.card-radio-button .mat-radio-label {
  padding: 15px;
  background: #eeeeee;
  border: 2px solid;
  border-color: #c3c3c3;
  border-radius: 5px;
}

.card-radio-button.client-radio .mat-radio-label {
  padding: 5px 15px;
  background: #eeeeee;
}

.card-radio-button .mat-radio-label-content {
  width: 100%;
}

.payments-filters .mat-form-field-prefix, .mat-form-field-suffix {
  display: flex !important;
}

.custom-dropzone-img img {
  max-width: 100%;
}

.mat-expansion-panel-header.custom-upload-header {
  min-height: 50px;
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ngx-mat-timepicker td {
  padding: 0;
  vertical-align: baseline;
  border-top: none;
}

.task-info:hover {
  cursor: pointer;
  background-color: #E0EFCE;
}

.task-card-background {
  padding: 10px;
  background: #e8fde7;
  border: 1px solid #cfe4ce;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
}

.cal-month-view .cal-day-cell {
  min-height: 80px;
}

.full-width, .input-full-width {
  width: 100%;
}

.input-fake {
  display: inline-block;
}

.clickable {
  cursor: pointer;
}

.select-all-checkbox {
  .mat-checkbox-layout {
    display: block !important;
    padding: 10px 10px 5px 10px;
  }
}

.custom-center .mat-sort-header-container {
  justify-content: center;
}

.mymoid-iframe html {
  background-color: transparent !important;
}

#toast-container.toast-top-right {
  top: 85px;
}

.splash-wrap {
  .mat-dialog-container {
    background-color: #04373d;
  }
}

.custom-task-dialog {
  overflow: auto;
  max-height: 950px;
}

.break-normal .mat-radio-label-content {
  white-space: normal;
}

.aep-table-input .mat-form-field-infix {
  width: auto;
}

.disabled-form {
  .mat-form-field {
    height: 40px;
  }

  input:disabled {
    color: inherit !important;
  }

  .mat-select-value {
    color: inherit !important;
  }

  .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
    background-image: none !important;
  }

  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0;
  }

  .col-form-label {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.single-case .mat-tab-header {
  display: none;
}

app-server-response, app-alert {
  width: 100%;
}

.list-horizontal {
  padding: 0;

  .mat-list-item {
    display: inline-block;
    height: auto;
    width: auto;
  }
}

/*.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: #fafbfb;
}*/

.client-form {
  .mat-row:nth-child(even) {
    background-color: #ffffff;
  }

  .mat-row:nth-child(odd) {
    background-color: #F8FAFB;
  }

  .mat-row:hover {
    //background-color: mat.get-color-from-palette($primary-palette);
    background-color: #cceeff;
  }
}

.case-relation-validation {
  /*.case-relation-validation .mat-horizontal-stepper-header-container{
    display: block;
  }*/
  h1 {
    font-size: 20px;
    color: #666666 !important;
  }

  h2 {
    font-size: 18px;
    color: #666666 !important;
  }

  .mat-horizontal-content-container {
    padding-left: 0;
    padding-right: 0;
  }

  th {
    font-weight: 400 !important;
  }

  tbody {
    th, td {
      color: #666666 !important;
    }
  }
}

.client-button-correct {
  background-color: #00B67A;
  border-color: #00B67A;
  border-radius: 5px;
  color: #fff;

  &:disabled {
    opacity: 0.25;
  }

  &:hover {
    color: #fff;
  }
}

.onboarding-wrap .mat-progress-spinner circle {
  stroke: #00B67A;
}

app-input .mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0 !important
}

@media (min-width: 992px) {
  .page-content {
    padding: 1.5rem 1rem;
  }
}

@media (max-width: 768px) {
  .page-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: 84vh !important;
  }
  .client-case-component {
    .mat-horizontal-stepper-header-container {
      background-color: #F9F9F9;
      height: 40px;
      border-width: 1px 0px;
      border-style: solid;
      border-color: #DFDFDF;
      margin-top: 25px;
    }
  }
  .case-relation-validation .mat-horizontal-stepper-header-container {
    display: flex !important;
    font-family: 'Inter' !important;
    font-size: 16px;
    flex-direction: row;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  mat-step-header:nth-of-type(2) {
    margin-right: 1.5rem;
    color: rebeccapurple;
  }
  .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background: transparent !important;
  }
  .mat-step-header:first-child {
    margin-left: -25px !important;
  }
  .mat-step-header .mat-step-icon {
    background-color: rgb(0 0 0 / 0%);
    color: #2764AE;
    font-weight: 700;

    &:last-child {
      display: none !important;
    }
  }
  .mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
    color: #242E61 !important;
  }
  .mat-step-header .mat-step-icon-selected {
    background-color: #2764AE;
  }
  mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #479D84 !important;
    color: #FFFFFF !important;
  }
  //Remove shadow when step clicked
  /*.mat-horizontal-stepper-header{
    pointer-events: none !important;
  }*/
  .mat-step-header .mat-step-icon-selected {
    background-color: #2764AE !important;
    color: #FFFFFF !important;
  }
  .mat-step-label {
    min-width: 89px !important;
    font-size: 16px !important;
  }
  .mat-stepper-horizontal-line {
    display: none;
  }
  .mat-step-header {
    margin-left: -35px !important;
  }
  .mat-horizontal-stepper-header {
    width: 10rem !important;
    padding: 0px 127px 0px 40px !important;
  }
  .mobile-nav-stepper {
    display: none !important;
  }

  .editor-wrap-modal {
    width: 100% !important;
  }
}

.light-bulb-icon svg {
  fill: #FFFFFF;
}

.light-bulb-icon svg:hover {
  fill: #a1bf4f;
}

.assets-editor .mat-form-field-infix {
  min-width: 100px;
  width: auto;
}

.card-header-actions {
  position: absolute;
  right: 16px;
  top: 16px;
}

mat-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between
}

mat-progress-bar.mat-progress-bar.mat-accent.fees-retained-progress .mat-progress-bar-buffer {
  background-color: #FFD70041;
}

mat-progress-bar.mat-progress-bar.mat-accent.fees-retained-progress svg {
  display: none;
}

mat-progress-bar.mat-progress-bar.mat-accent.fees-retained-progress .mat-progress-bar-fill::after {
  background-color: #FFD700;
}

.onboarding-wrap .mat-progress-bar-fill::after {
  background: #549FD7;
  border-radius: 1000px;
}

.onboarding-wrap .mat-progress-bar-buffer {
  background: #EEF5FB;
}

.login-container, .forgot-password-container {
  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #355270 !important;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: #355270 !important;
  }
}

.payment-desktop-container {
  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #2764AE !important;
  }
}

.document-upload {
  .mat-expansion-indicator {
    margin-left: 1rem;
  }
}

.details-step-container {

  .mat-slide-toggle {
    transform: scale(1.5);
    float: right;
    margin-right: 12px;

    &.mat-checked {
      .mat-slide-toggle-thumb {
        height: 10px;
        width: 10px;
        margin-top: 4.5px;
        margin-left: 12px;
      }

      .mat-slide-toggle-bar::after {
        content: 'Correcto';
        font-size: 38%;
        font-weight: 400;
        color: white;
        position: absolute;
        left: 5px;
        top: -5px;
      }
    }

    &:not(.mat-checked) {
      .mat-slide-toggle-thumb {
        height: 10px;
        width: 10px;
        margin-top: 4.5px;
        margin-left: 1px;
      }

      .mat-slide-toggle-bar::after {
        content: 'Incorrecto';
        font-size: 38%;
        font-weight: 400;
        color: white;
        position: absolute;
        left: 13px;
        top: -5px;
        padding-right: 42px !important;
      }
    }
  }

  .no-radio-btn.mat-radio-button.mat-primary .mat-radio-inner-circle {
    background-color: #B60000;
  }

  .no-radio-btn.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #B60000 !important;
  }

  /*.yes-radio-btn {
    .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
      border-color: #2764ae;
    }

    .mat-radio-button.mat-primary .mat-radio-inner-circle {
      background-color: #2764ae;
    }
  }*/
  .mat-slide-toggle .mat-ripple-element {
    background-color: transparent !important;
  }

  .mat-slide-toggle-bar {
    background-color: #8A2020 !important;

    &:not(.mat-checked) {
      padding-right: 42px !important;
    }
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #14C387 !important;
    padding-right: 0px !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #FFFFFF;
  }

  .mat-slide-toggle-bar {
    width: 40px;
  }

}

.client-typography {
  h4, h3, h2, p {
    font-family: 'Poppins' !important;
  }
}

.client-typography-v2 {
  h4, h3, h2, p, spam {
    font-family: 'Inter' !important;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #242E61 !important;
}


.onboarding-stepper-desktop {
  .mat-step-header {
    display: none !important;
  }

  .mat-stepper-vertical-line::before {
    top: unset !important;
    background: transparent !important;
  }
}


.mat-card-fixed-width {
  mat-card {
    height: 240px;
  }
}

.password-desktop-container {
  .mat-tab-group {
    flex-direction: row;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-header-pagination {
    display: none !important;
  }

  .mat-tab-labels {
    flex-direction: column;
  }

  .mat-ink-bar {
    height: 100%;
    left: 98% !important;
  }

  .mat-tab-body-wrapper {
    flex: 1 1 auto;
  }

  .mat-tab-label-container {
    background: #F9F9F9;
    border-right: 1px solid #DFDFDF;
  }

  .mat-ink-bar {
    visibility: hidden !important;
  }

  .mat-tab-label, .mat-tab-link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #242E61;

    /*&.mat-tab-disabled {
      color: green;
    }*/
  }

  .mat-tab-label.mat-tab-label-active {
    color: #242E61;
    font-weight: 700;
  }
}

.z-index-4000 {
  z-index: 4000 !important;
}

.client-typography-input {
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.34375em) scale(1) !important; /* originally scale(0.75) */
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
  .mat-form-field.mat-form-field-should-float label {
    font-size: 17px;
  }
  mat-label {
    font-family: 'Poppins';
    font-size: 17px !important;
  }
  .mat-form-field-label {
    font-size: 0.8em !important;
  }
  .mat-checkbox-layout .mat-checkbox-label{
    font-family: 'Poppins';
    font-size: 14px;
  }
}

#cx-chat-btn {
  display: none !important;
}
.animated__fadeInRight .navbar__button{
  display: none !important;
  opacity: 0 !important;
}
#cx-nudge{
  display: none !important;
}

.icon-blue{
  .swal2-success-line-tip{
    background-color: #0a6ebd !important;
  }
  .swal2-success-line-long{
    background-color: #0a6ebd !important;
  }
  .swal2-success-ring {
    border-color: #0a6ebd !important;
  }
}

.select-all-checkbox .mat-checkbox-layout{
  margin-left: 0.4rem !important;
}

.payment-desktop-container{
  .mat-radio-label-content{
    font-family: 'Poppins', serif !important;
  }
}

// Noire / Cashflows form styles
.cnpBillingCheckoutWrapper {
  position: relative;
}

.cnpBillingCheckoutHeader {
  width: 100%;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 10px;
}

.cnpBillingCheckoutLeft {
  width: 240px;
  margin-left: 5px;
  margin-bottom: 10px;
  border: 1px solid #c0c0c0;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
}

.cnpBillingCheckoutRight {
  width: 50%;
  margin-left: 5px;
  border: 1px solid #c0c0c0;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
}

.cnpBillingCheckoutOrange {
  font-size: 110%;
  color: rgb(255, 60, 22);
  font-weight: bold;
}

div.wpwl-wrapper, div.wpwl-label, div.wpwl-sup-wrapper {
  width: 100%;
}

div.wpwl-group-expiry, div.wpwl-group-brand {
  width: 30%;
  float: left;
}

div.wpwl-group-cvv {
  width: 68%;
  float: left;
}

div.wpwl-group-cardHolder, div.wpwl-sup-wrapper-street1, div.wpwl-group-expiry {
  clear: both;
}

div.wpwl-sup-wrapper-street1 {
  padding-top: 1px;
}

div.wpwl-wrapper-brand {
  width: auto;
}

div.wpwl-sup-wrapper-state, div.wpwl-sup-wrapper-city {
  width: 32%;
  float: left;
  margin-right: 2%;
}

div.wpwl-sup-wrapper-postcode {
  width: 32%;
  float: left;
}

div.wpwl-sup-wrapper-country {
  width: 66%;
}

div.wpwl-wrapper-brand, div.wpwl-label-brand, div.wpwl-brand {
  display: none;
}

div.wpwl-group-cardNumber {
  width: 60%;
  float: left;
  font-size: 20px;
}

div.wpwl-group-brand {
  width: 35%;
  float: left;
  margin-top: 28px;
}

div.wpwl-brand-card {
  width: 65px;
}

div.wpwl-brand-custom {
  margin: 0px 5px;
}

.wpwl-button-pay {
  float: right !important;
  height: 40px;
  font-family: "Inter";
  width: 128px;
  font-size: 16px;
  background: #2764AE;
  border-radius: 4px;
  color: #FFFFFF;
  border: none;
}

.wpwl-button-pay:hover {
  background: #2764AE !important;
  color: #FFFFFF;

}

div.wpwl-group-brand {
  display: block !important;
}

div.wpwl-group-expiry {
  margin-right: 10px;
}

div.wpwl-group-cvv {
  width: 67%;
}

.wpwl-label-cardNumber {
  font-size: 16px !important;
}

.wpwl-wrapper-cardNumber {
  font-size: 16px !important;
}

.wpwl-icon {
  background: none !important;
  border: none !important;
}

// End Noire Styles
